import zenItIcon from 'v1/assets/icons/navigation/zen-it-icon.svg'
import iphoneChargingIcon from 'v1/assets/icons/navigation/iphone-old-charging.svg'
import appsIcon from 'v1/assets/icons/navigation/apps-icon.svg'
import shieldLockIcon from 'v1/assets/icons/navigation/shield-lock-icon.svg'
import warehouseIcon from 'v1/assets/icons/navigation/warehouse-icon.svg'
import chatsIcon from 'v1/assets/icons/navigation/chats-icon.svg'

import productCardImage from 'v1/assets/icons/navigation/products-card-image.png'
import rightBlueArrowIcon from 'v1/assets/icons/navigation/arrow-right-blue.svg'

import zenCoreIcon from 'v1/assets/icons/navigation/zen-core-icon.svg'
import userSearchIcon from 'v1/assets/icons/navigation/user-search.svg'
import folderFolderIcon from 'v1/assets/icons/navigation/document-paper.svg'
import flow3Icon from 'v1/assets/icons/navigation/flow-2.svg'
import analyticsIcon from 'v1/assets/icons/navigation/analytics.svg'
import documentAwardIcon from 'v1/assets/icons/navigation/document-award.svg'
import chatTextIcon from 'v1/assets/icons/navigation/chat-text.svg'

import userTieIcon from 'v1/assets/icons/navigation/for-founders-icon.svg'
import shieldLock2Icon from 'v1/assets/icons/navigation/become-security-icons.svg'
import moneyFlashIcon from 'v1/assets/icons/navigation/save-cost-icon.svg'
import emmyLogo from 'v1/assets/icons/navigation/emmy-logo.svg'
import robertCokImage from 'v1/assets/icons/navigation/robert-cook-image.svg'

import buildingIcon from 'v1/assets/icons/navigation/about-us-icon.svg'
import briefcaseIcon from 'v1/assets/icons/navigation/careers-icon.svg'
import phoneMessageIcon from 'v1/assets/icons/navigation/contact-us-icon.svg'
import textAlignLeftIcon from 'v1/assets/icons/navigation/blogs-icon.svg'
import blog1Image from 'v1/assets/icons/navigation/blog-1-image.png'
import blog2Image from 'v1/assets/icons/navigation/blog-2-image.png'

import {StaticImageData} from 'next/image'
import {
  EMPLOYEE_DIRECTORY,
  DOCUMENT_MANGEMENT,
  WORKFLOW_AUTOMATION,
  REPORTS_AND_ANALYTICS,
  POLICIES_AND_PERMISSIONS,
  BLOG,
  CAREERS,
  ABOUT_US,
  WHY_HYBR1D,
  ZEN_IT,
  IT_HELPDESK,
  SAAS_MANAGEMENT,
  INVENTORY_MANAGEMENT,
  IDENTITY_MANAGEMENT,
  BECOME_SECURITY_COMPLIANT,
  FOR_FOUNDERS_HR_AND_IT_ADMINS,
  SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES,
  MOBILE_DEVICE_MANAGEMENT,
  ZEN_IT_HOME,
} from 'v1/utils/getters'

export interface INavigationLink {
  heading: string
  url?: string
  external?: boolean

  links?: {title: string; url: string; external?: boolean; icon?: StaticImageData}[]
  customersData?: {
    title: string
    card: {
      logo: StaticImageData
      text: string
      details: {
        image: StaticImageData
        text: string
      }
    }
  }
  blogData?: {
    title: string
    cards: {
      image: StaticImageData
      text: string
      linkText: string
      link: string
      icon: StaticImageData
    }[]
  }
  tabs?: {
    heading?: string
    links: {
      icon: StaticImageData
      title: string
      text?: string
      url: string
      isExternal?: boolean
    }[][]
    name: string
    key: string
    icon: StaticImageData
  }[]
  rightSideCard?: {
    title: string
    text1: string
    text2: string
    linkText: string
    link: string
    image: StaticImageData
    icon: StaticImageData
  }
}

export const navigationLinks: INavigationLink[] = [
  {
    heading: 'Products',
    tabs: [
      {
        name: 'Zen IT',
        key: 'zenit',
        icon: zenItIcon,
        links: [
          [
            {
              icon: warehouseIcon,
              title: 'Inventory Management',
              text: 'Global Logistics, Procurement and more',
              url: INVENTORY_MANAGEMENT,
            },

            {
              icon: appsIcon,
              title: 'SaaS Management',
              text: 'Manage Apps and Subscriptions',
              url: SAAS_MANAGEMENT,
            },
            {
              icon: shieldLockIcon,
              title: 'Identity & Access Management',
              text: 'Manage User Identity and Access',
              url: IDENTITY_MANAGEMENT,
            },
          ],
          [
            {
              icon: iphoneChargingIcon,
              title: 'Mobile Device Management',
              text: 'One Solution to Secure Your Device',
              url: MOBILE_DEVICE_MANAGEMENT,
            },

            {
              icon: chatsIcon,
              title: 'IT Helpdesk',
              text: 'Seamless IT Helpdesk Support',
              url: IT_HELPDESK,
            },
          ],
        ],
      },
      {
        name: 'Zen Core',
        key: 'zencore',
        icon: zenCoreIcon,
        links: [
          [
            {
              icon: userSearchIcon,
              title: 'Employee Directory',
              text: 'Store and Manage All Your Data',
              url: EMPLOYEE_DIRECTORY,
            },
            {
              icon: folderFolderIcon,
              title: 'Document management',
              text: 'Manage Document with Ease',
              url: DOCUMENT_MANGEMENT,
            },
            {
              icon: flow3Icon,
              title: 'Workflow automation',
              text: 'Automate Your People Processes',
              url: WORKFLOW_AUTOMATION,
            },
          ],
          [
            {
              icon: analyticsIcon,
              title: 'Report and Analytics',
              text: 'Instant Data Export with ZenAdmin',
              url: REPORTS_AND_ANALYTICS,
            },
            {
              icon: documentAwardIcon,
              title: 'Policies & Permissions',
              text: 'Automate Employee Access',
              url: POLICIES_AND_PERMISSIONS,
            },
            {
              icon: chatTextIcon,
              title: 'Employee Experience',
              text: 'Maximize Efficiency and Focus',
              url: '',
            },
          ],
        ],
      },
    ],
    rightSideCard: {
      title: 'Revolutionize Your IT Support',
      text1: '24/7/365 L1 and L2 Support, ',
      text2: 'Guaranteed 15-Minute Response Time',
      linkText: 'Learn more about Zen IT',
      link: ZEN_IT_HOME,
      image: productCardImage,
      icon: rightBlueArrowIcon,
    },
  },
  {
    heading: 'Solutions',
    links: [
      {
        title: 'For Founders, HR and IT Admins',
        url: FOR_FOUNDERS_HR_AND_IT_ADMINS,
        icon: userTieIcon,
      },
      {title: 'Become Security Compliant', url: BECOME_SECURITY_COMPLIANT, icon: shieldLock2Icon},
      {
        title: 'Save Costs & Establish Scalable Processes',
        url: SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES,
        icon: moneyFlashIcon,
      },
    ],
    customersData: {
      title: 'Customers',
      card: {
        logo: emmyLogo,
        text: '“I only regret not outsourcing this topic earlier, hiring ZenAdmin has taken away so much of my headache. What I value most is that we have been able to streamline IT administration processes, remove IT security risks and our internal resources can now concentrate fully on our business again.”',
        details: {
          image: robertCokImage,
          text: 'Robert Cook, IT Manager  |  Emmy',
        },
      },
    },
  },
  {
    heading: 'Company',
    links: [
      {title: 'About us', url: ABOUT_US, icon: buildingIcon},
      {title: 'Careers', url: CAREERS, external: true, icon: briefcaseIcon},
      // {title: 'Contact us', url: ABOUT_US, icon: phoneMessageIcon},
      // {title: 'Blogs', url: BLOG, icon: textAlignLeftIcon},
    ],
    blogData: {
      title: 'Our Blogs',
      cards: [
        {
          image: blog1Image,
          text: 'How ZenAdmin is Empowering IT Teams to Achieve Efficiency & Success',
          linkText: 'Read more',
          link: '/blogs/how-zenadmin-is-empowering-it-teams-to-achieve-efficiency-success',
          icon: rightBlueArrowIcon,
        },
        {
          image: blog2Image,
          text: 'Introducing ZenAdmin’s Enhanced Dashboard: Revolutionizing IT Operations',
          linkText: 'Read more',
          link: '/blogs/zenadmins-enhanced-it-dashboard-revolutionizing-it-operations',
          icon: rightBlueArrowIcon,
        },
      ],
    },
  },
  {
    heading: 'Why ZenAdmin',
    url: WHY_HYBR1D,
  },
  {
    heading: 'Blog',
    url: BLOG,
  },
]
