'use client'
import * as React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import clsx from 'clsx'
import Button, {ButtonType} from 'v1/common-components/button/Button'
import SmallLogo from 'v1/assets/icons/small-logo.svg'
import ZenAdminLogo from 'v1/assets/icons/zenadmin-logo.svg'
import LoginIcon from 'v1/assets/icons/login-icon.svg'
import ArrowRightIcon from 'v1/assets/icons/arrow-right.svg'
import ChevronDownBlack from 'assets/icons/common/chevron-down-black.svg'
import ChevronDownWhite from 'assets/icons/common/chevron-down-white.svg'
import menuIcon from 'v1/assets/icons/menu.svg'
import classes from './styles.module.css'
import {navigationLinks} from 'v1/content/navigation'
import {HYBRID_APP, DEMO_LINK} from 'v1/utils/getters'
import ProduvtTabs from './product-tabs/ProductTabs'
import SolutionsTab from './solutions-company-tabs/SolutionAndCompanyTabs'
import Modal from 'v1/common-components/modal/Modal'
import BookDemoForm from 'v1/page-components/marketing/common/demo-form/BookDemoForm'

interface NavigationProps {
  customLinkClassName?: string
  isDarkDownArrow?: boolean
  signInBtnVariant?: ButtonType
  signInBtnCustomCss?: React.CSSProperties
  customLineClassName?: string
  withoutList?: boolean
  withoutLogingBtn?: boolean
  showStartFreeTrialBtn?: boolean
}

interface NavItem {
  heading: string
  url?: string
  external?: boolean
}
interface DemoData {
  isFreeTrialPage?: boolean
  isNameRequired?: boolean
  heading?: string
  buttonText?: string
  isTermAndCondition?: boolean
  thankYouTitle?: string
  thankYouParagraph?: string
}
const defaultDemoData = {
  isFreeTrialPage: true,
  isNameRequired: true,
  heading: 'Get ZenAdmin for Free',
  buttonText: 'Submit',
  isTermAndCondition: true,
  thankYouTitle: 'Your Account Has Been Created',
  thankYouParagraph:
    'Please check your email for login details to access your ZenAdmin account. Meanwhile, you can schedule a convenient time for a personalized demo.',
}

const Navigation: React.FC<NavigationProps> = ({
  withoutList = false,
  withoutLogingBtn = false,
  customLinkClassName = '',
  isDarkDownArrow = true,
  signInBtnVariant = 'secondary',
  signInBtnCustomCss,
  customLineClassName = '',
  showStartFreeTrialBtn = false,
}) => {
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false)
  const [activeNav, setActiveNav] = React.useState<string | null>(null)
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false)
  const [demoData, setDemoData] = React.useState<DemoData>({...defaultDemoData})

  const toggleDropdown = (heading: string) => {
    setActiveNav(activeNav === heading ? null : heading)
  }

  const toggleDemoFormModal = (data: DemoData | null = null) => {
    setDemoData(
      data || {
        ...defaultDemoData,
      },
    )
    setModalOpen(prev => !prev)
  }

  const renderDropdownNavItem = (navItem: NavItem, Component: React.FC<any>) => (
    <div
      className={clsx(
        classes.link,
        classes.dropdownGroup,
        customLinkClassName,
        activeNav === navItem.heading ? classes.activeNavText : '',
      )}
      onClick={() => toggleDropdown(navItem.heading)}
    >
      {navItem.heading}
      <Image
        src={isDarkDownArrow ? ChevronDownBlack : ChevronDownWhite}
        alt="chevron-down"
        height={20}
        width={20}
        className={classes.downArrow}
      />
      {activeNav === navItem.heading && (
        <Component navItem={navItem} isWebNavOpen onClose={() => toggleDropdown(navItem.heading)} />
      )}
    </div>
  )

  const renderNavItem = (navItem: NavItem, index: number) => {
    const components: Record<string, React.FC<any>> = {
      Products: ProduvtTabs,
      Solutions: SolutionsTab,
      Company: SolutionsTab,
    }
    const Component = components[navItem.heading]

    return Component
      ? renderDropdownNavItem(navItem, Component)
      : navItem.external
      ? renderExternalNavItem(navItem, index)
      : renderInternalNavItem(navItem, index)
  }

  const renderExternalNavItem = (navItem: NavItem, index: number) => (
    <a
      key={index}
      href={navItem.url}
      className={clsx(classes.link, customLinkClassName)}
      target="_blank"
      rel="noreferrer"
    >
      {navItem.heading}
    </a>
  )

  const renderInternalNavItem = (navItem: NavItem, index: number) => (
    <Link key={index} href={navItem.url || ''} className={clsx(classes.link, customLinkClassName)}>
      {navItem.heading}
    </Link>
  )

  const renderActionButton = (text: string, iconRight?: React.ReactNode, href?: string) => (
    <Link href={href || ''} target="_blank">
      <Button variant="primary" text={text} iconRight={iconRight} style={{gap: '9px'}} />
    </Link>
  )

  const renderNavLinks = () => (
    <>
      {!withoutLogingBtn && (
        <Link href={HYBRID_APP} target="_blank">
          <Button
            iconLeft={<Image src={LoginIcon} alt="login-icon" />}
            variant={signInBtnVariant}
            text="Login"
            style={signInBtnCustomCss}
          />
        </Link>
      )}
      {showStartFreeTrialBtn ? (
        <>
          {renderActionButton('Book a demo', undefined, DEMO_LINK)}
          {renderActionButton(
            'Start free trial',
            <span className={classes.btnIconBox}>
              <Image src={ArrowRightIcon} alt="arrow-right" width={18} height={18} />
            </span>,
            DEMO_LINK,
          )}
        </>
      ) : (
        renderActionButton(
          'Book a demo',
          <span className={classes.btnIconBox}>
            <Image src={ArrowRightIcon} alt="arrow-right" width={18} height={18} />
          </span>,
          DEMO_LINK,
        )
      )}
    </>
  )

  return (
    <>
      <nav className={classes.navbar}>
        <div className={classes.navbarInnerContainer}>
          <div className={classes.logo}>
            <Link href="/" className={classes.companyLogo}>
              <Image src={SmallLogo} alt="logo" height={32} />
              <Image src={ZenAdminLogo} alt="logo" height={16} />
            </Link>
          </div>
          {!withoutList && (
            <div className={classes.links}>
              {navigationLinks.map((navItem, index) => (
                <React.Fragment key={index}>{renderNavItem(navItem, index)}</React.Fragment>
              ))}
            </div>
          )}
          <div className={classes.actions}>
            <div className={classes.actionBtnContainer}>{renderNavLinks()}</div>
            {!withoutList && (
              <div
                className={clsx(classes.hamburger, {[classes.active]: isMobileNavOpen})}
                onClick={() => setMobileNavOpen(!isMobileNavOpen)}
              >
                <Image src={menuIcon} width={16} alt="menu-icon" />
              </div>
            )}
          </div>
        </div>
      </nav>

      <nav className={clsx(classes.mobileNavbar, {[classes.open]: isMobileNavOpen})}>
        <div className={clsx(classes.mobileLinks)}>
          {navigationLinks.map((link, index) =>
            link.tabs ? (
              <div key={index} className={clsx(classes.mobileDropdownGroup)}>
                <div className={classes.mobileLink} onClick={() => toggleDropdown(link.heading)}>
                  {link.heading}
                  <Image src={ChevronDownBlack} alt="chevron-down" height={20} width={20} />
                </div>
                <nav className={classes.mobileDropdownNav}>
                  {link.tabs[0]?.links?.map((subLinks, idx) =>
                    subLinks.map((subLink, subIdx) =>
                      subLink.isExternal ? (
                        <a
                          key={subIdx}
                          href={subLink.url}
                          className={classes.subLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {subLink.title}
                        </a>
                      ) : (
                        <Link
                          key={subIdx}
                          href={subLink.url}
                          className={classes.subLink}
                          onClick={() => setMobileNavOpen(false)}
                        >
                          {subLink.title}
                        </Link>
                      ),
                    ),
                  )}
                </nav>
              </div>
            ) : link.external ? (
              renderExternalNavItem(link, index)
            ) : (
              renderInternalNavItem(link, index)
            ),
          )}
        </div>
      </nav>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <BookDemoForm data={demoData} />
      </Modal>
    </>
  )
}

export default Navigation
