import React, {useState} from 'react'
import Link from 'next/link'
import {motion} from 'framer-motion'
import Image from 'next/image'
import clsx from 'clsx'
import {navigationLinks, INavigationLink} from 'v1/content/navigation'

import arrowRightIcon from 'v1/assets/icons/navigation/arrow-right-icon.svg'
import classes from './styles.module.css'

interface ProductTabsProps {
  navItem: INavigationLink
  isWebNavOpen: boolean
  onClose: () => void
}

export default function ProductTabs({navItem, isWebNavOpen, onClose}: ProductTabsProps) {
  const [activeTab, setActiveTab] = useState(navigationLinks?.[0]?.tabs?.[0]?.key)

  return (
    <div className={classes.overlayBackground} onClick={onClose}>
      <motion.div
        initial={{y: -0, opacity: 0.4}}
        animate={isWebNavOpen ? {y: 0, opacity: 1} : {y: -10, opacity: 0}}
        exit={{y: -80, opacity: 0}}
        transition={{duration: 0.4}}
        className={classes.navigationListMainContainer}
        onClick={e => e.stopPropagation()}
      >
        <div className={classes.navLinksAndTabsContainer}>
          <div className={classes.navLinksAndTabs}>
            <div className={classes.tabContainer}>
              {navItem.tabs?.map(tab => (
                <div
                  id={tab.key}
                  key={tab.key}
                  className={clsx(classes.tab, tab.key === activeTab ? classes.activeTab : '')}
                  onMouseOver={event => {
                    event.stopPropagation()
                    setActiveTab(tab.key)
                  }}
                >
                  <div className={classes.tabInnerArea}>
                    <Image src={tab.icon} alt="icon" />
                    <p>{tab.name}</p>
                  </div>
                  {tab.key === activeTab ? <Image src={arrowRightIcon} alt="right-arrow" /> : null}
                </div>
              ))}
            </div>
            <div className={classes.tabLinksContainer}>
              {navItem.tabs?.map((tab, idx) => (
                <div key={idx}>
                  {tab.key === activeTab && (
                    <div className={classes.navListContainer}>
                      {tab?.links.map((links, linkIdx) => (
                        <div key={linkIdx} className={classes.linksContainer}>
                          {links?.map((link, index) => (
                            <Link href={link.url} key={index}>
                              <div className={classes.linkBox} key={index}>
                                <Image width={24} src={link.icon} alt="icon" />
                                <div className={classes.linkTextDetails}>
                                  <h5 className={classes.linkTitle}>{link.title}</h5>
                                  <p className={classes.rightSideCardText}>{link.text}</p>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={classes.rightSideCard}>
            <div className={classes.rightSideInnerCard}>
              <Image src={navItem?.rightSideCard?.image || ''} alt="image" />
              <div className={classes.rightSideCardTextDetail}>
                <h5 className="h6-v1">{navItem.rightSideCard?.title}</h5>
                <p className={classes.rightSideCardText}>
                  {navItem.rightSideCard?.text1} <br /> {navItem.rightSideCard?.text2}
                </p>
              </div>
            </div>
            <Link
              href={navItem?.rightSideCard?.link || ''}
              className={clsx('h7-v1', classes.cardLinkText)}
            >
              {navItem?.rightSideCard?.linkText}
              <Image src={navItem?.rightSideCard?.icon || ''} alt="icon" />
            </Link>
          </div>
        </div>
      </motion.div>
    </div>
  )
}
